import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import projectsReducer from "./projectsReducer";
import schachtReducer from "./schachtReducer";
import workflowReducer from "./workflowReducer";
import uploadReducer from "./uploadReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  projects: projectsReducer,
  schachts: schachtReducer,
  workflow: workflowReducer,
  pictures: uploadReducer
});