import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem, Checkbox, FormGroup, FormControlLabel, FormLabel, RadioGroup, Radio, StylesProvider, Divider} from "@material-ui/core";

import './Step.scss';

class MaintenanceCoverForm extends Component {
  
render() {
	let maengelDeckel
	if (this.props.values.ist_maengel_deckel === true) {
	maengelDeckel = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_deckel_defekt' + this.props.values.id}
				name="ist_deckel_defekt"
				checked={this.props.values.ist_deckel_defekt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Defekt"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_deckel_korrodiert' + this.props.values.id}
				name="ist_deckel_korrodiert"
				checked={this.props.values.ist_deckel_korrodiert}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Korrodiert"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_deckel_klemmt' + this.props.values.id}
				name="ist_deckel_klemmt"
				checked={this.props.values.ist_deckel_klemmt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Klemmt (Kann nicht geöffnet werden)"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_deckel_verschraubt' + this.props.values.id}
				name="ist_deckel_verschraubt"
				checked={this.props.values.ist_deckel_verschraubt}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Verschraubt"
           />
	    </FormGroup>
        </Grid>
		)
	} else {
		maengelDeckel = null
	}
	
	let maengelRahmen
	if (this.props.values.ist_maengel_rahmen === true) {
	maengelRahmen = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_rahmen_lose' + this.props.values.id}
				name="ist_rahmen_lose"
				checked={this.props.values.ist_rahmen_lose}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="lose"
           />
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_rahmen_mangel_unterbetoniert' + this.props.values.id}
				name="ist_rahmen_mangel_unterbetoniert"
				checked={this.props.values.ist_rahmen_mangel_unterbetoniert}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Mangelhaft unterbetoniert"
           />
	    </FormGroup>
        </Grid>
		)
	} else {
		maengelRahmen = null
	}

	let belueftungSchlammeimer
	if (this.props.values.ist_belueftung === true) {
	belueftungSchlammeimer = (
      <Grid item xs={12} sm={6}>
		<FormGroup>
		   <FormControlLabel
             control={
		      <Checkbox
				id={'ist_belueftung_schlammeimer' + this.props.values.id}
				name="ist_belueftung_schlammeimer"
				checked={this.props.values.ist_belueftung_schlammeimer}
				onChange={this.props.handleCheckChange}
				color="default"
              />
		     }
           label="Schlammeimer vorhanden"
           />
	    </FormGroup>
        </Grid>
		)
	} else {
		belueftungSchlammeimer = null
	}
	
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Zustand Deckel</Typography>
        </Grid>
		<StylesProvider injectFirst>
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		  <FormGroup>
		     <FormLabel component="legend">Mängel Deckel*</FormLabel>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_maengel_deckel"
			   value={this.props.values.ist_maengel_deckel}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio required={true} /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                 control={<Radio required={true} /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
			 </RadioGroup>
			</FormGroup>
		  </Grid>
          {maengelDeckel}
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		  <FormGroup>
		     <FormLabel component="legend">Mängel Rahmen*</FormLabel>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_maengel_rahmen"
			   value={this.props.values.ist_maengel_rahmen}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio required={true} /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                 control={<Radio required={true} /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
			 </RadioGroup>
			</FormGroup>
		  </Grid>
          {maengelRahmen}
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Deckel ersetzten</FormLabel>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_ersetze_deckel"
			   value={this.props.values.ist_ersetze_deckel}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                   control={<Radio /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
			 </RadioGroup>
			</FormGroup>
		  </Grid>
	    </Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Rahmen ersetzten</FormLabel>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_ersetzte_rahmen"
			   value={this.props.values.ist_ersetzte_rahmen}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                   control={<Radio /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
		</Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Belüftet</FormLabel>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_belueftung"
			   value={this.props.values.ist_belueftung}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                   control={<Radio /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
		  {belueftungSchlammeimer}
		</Grid>
		<Divider />
		<Grid container item xs={12}>
		  <Grid item xs={12} sm={6}>
		    <FormGroup>
		     <FormLabel component="legend">Verschluss</FormLabel>
			 <RadioGroup
               row
               aria-label="position"
			   name="ist_verschluss"
			   value={this.props.values.ist_verschluss}
			   onChange={this.props.handleRadioChange}
			 >
		       <FormControlLabel
			     value={true}
                 control={<Radio /> }
			     label="Ja"
			     labelPlacement="start"
                 />
			   <FormControlLabel
			     value={false}
                   control={<Radio /> }
			     label="Nein"
			     labelPlacement="end"
			     className="input-no"
               />
		  	  </RadioGroup>
	        </FormGroup>
		  </Grid>
		</Grid>
		</StylesProvider>
		<Grid item xs={12} sm={12}>
		  <TextField
            id={'bemerkung' + this.props.values.id}
		    name='bemerkung_zustand_deckel'
            label="Bemerkung"
            multiline
			fullWidth
			rows={4}
            value={this.props.values.bemerkung_zustand_deckel}
			onChange={this.props.handleChange}
			variant="outlined"
          />
		</Grid>
    </>
	)
}
}

export default MaintenanceCoverForm;
