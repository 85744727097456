// Authentication
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Projects
export const GET_PROJECTS_OF_USER = "GET_PROJECTS_OF_USER";
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const GET_PROJECT_OF_USER = "GET_PROJECT_OF_USER";
export const PROJECT_LOADING = "PROJECT_LOADING";

// Schacht
export const CREATE_SCHACHT = "CREATE_SCHACHT";
export const UPDATE_SCHACHT_GEOM = "UPDATE_SCHACHT_GEOM";
export const UPDATE_SCHACHT_ATTR = "UPDATE_SCHACHT_ATTR";
export const UPDATE_SCHACHT_ZUGANG = "UPDATE_SCHACHT_ZUGANG";
export const UPDATE_SCHACHT_KEK = "UPDATE_SCHACHT_KEK";
export const UPDATE_SCHACHT_CANVAS = "UPDATE_SCHACHT_CANVAS";
export const DELETE_SCHACHT = "DELETE_SCHACHT";
export const GET_SCHACHT = "GET_SCHACHT";
export const GET_SCHACHT_DATASHEET = "GET_SCHACHT_DATASHEET";
export const SCHACHT_LOADING = "SCHACHT_LOADING";
export const GET_SCHACHTS = "GET_SCHACHTS";
export const SCHACHTS_LOADING = "SCHACHTS_LOADING";
export const GET_MATERIAL_SCHACHT = "GET_MATERIAL_SCHACHT";
export const GET_MATERIAL_DECKEL = "GET_MATERIAL_DECKEL";
export const GET_FUNKTION = "GET_FUNKTION";
export const GET_FORM = "GET_FORM";
export const GET_NUTZUNG = "GET_NUTZUNG";
export const GET_STATUS = "GET_STATUS";
export const GET_OBERFLAECHENZULAUF = "GET_OBERFLAECHENZULAUF";
export const GET_ZUGANG = "GET_ZUGANG";
export const GET_EINSTIEGSHILFE = "GET_EINSTIEGSHILFE";
export const GET_SCHACHTBEREICH = "GET_SCHACHTBEREICH";
export const GET_SCHADENCODE = "GET_SCHADENCODE";

//Zustand
export const GET_DECKEL_ZUSTAND = "GET_DECKEL_ZUSTAND";
export const GET_SCHACHT_ZUSTAND = "GET_SCHACHT_ZUSTAND";
export const CREATE_DECKEL_ZUSTAND = "CREATE_DECKEL_ZUSTAND";
export const CREATE_SCHACHT_ZUSTAND = "CREATE_SCHACHT_ZUSTAND";

// Upload
export const UPLOAD_IMG = "UPLOAD_IMG";
export const CREATE_PICTURE = "CREATE_PICTURE";