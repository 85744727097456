import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AppBar, Toolbar, Typography, Grid, Container, Paper, Box, } from "@material-ui/core";

import CustomizedSteppers from "./Stepper";
import KEKSteppers from "./KEKStepper";

import Karte from "../map/Map";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";


import './Workflow.scss';
import logo from "../../img/logo_mobile.png";


import { getSchacht, getSchachts, getMaterialSchacht, getMaterialDeckel, getFunktion, getDeckelZustand, getSchachtZustand, getForm, getEinstiegshilfe, getSchachtbereich, getSchadencode, getNutzung, getStatus, getOberflaechenzulauf, getZugang } from "../../actions/schachtActions";

class Workflow extends Component {
	  
componentDidMount() {
    let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.add("top-nav-invisibile"); }
	  
    this.props.getSchacht(this.props.match.params.id);
	this.props.getDeckelZustand(this.props.match.params.id);
	this.props.getSchachtZustand(this.props.match.params.id);
	this.props.getMaterialSchacht();
	this.props.getMaterialDeckel();
	this.props.getFunktion();
	this.props.getForm();
	this.props.getNutzung();
	this.props.getStatus();
	this.props.getOberflaechenzulauf();
	this.props.getZugang();
	this.props.getEinstiegshilfe();
	this.props.getSchachtbereich();
	this.props.getSchadencode();
  }

    componentDidUpdate(prevProps) {
       if (this.props.match.params.id !== prevProps.match.params.id) {
         this.props.getSchacht(this.props.match.params.id);
       }
  }


  render() {
	let stepperContent;
    if (this.props.schacht.id_object === 2) {
		stepperContent = <KEKSteppers history={this.props.history}/>;
	}
	else {
		stepperContent = <CustomizedSteppers history={this.props.history}/>;
	}
    return (
	    <div>
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                             <img src={logo} className="wf-logo-mobile" alt="logo" height="70px" />
                         </Grid>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>Schachtprotokolle</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
	        <Box component="main" className="wf-box-wrapper">
                <Container maxWidth="md" className="wf-container">
                    <Paper elevation={5}>
						{stepperContent}
                    </Paper>
                </Container>
            </Box>
	    </div>

    );
  }
}


Workflow.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  schacht: state.schachts.schacht
});

export default connect(
  mapStateToProps,
    { getSchacht,
      getSchachts,
	  getMaterialSchacht,
	  getMaterialDeckel,
	  getFunktion,
	  getForm,
	  getNutzung,
	  getStatus,
	  getOberflaechenzulauf,
	  getZugang,
	  getEinstiegshilfe,
	  getSchachtbereich,
	  getSchadencode,
	  getDeckelZustand,
	  getSchachtZustand}
)(Workflow);