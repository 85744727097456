import React, { Component } from "react";
import { connect } from "react-redux";
// Style
import "./MainContent.scss";
import "./Dashboard.scss";
// Material UI
import { AppBar, Toolbar, Typography, Grid, GridList, GridListTile,  Container, Paper, Box, StepLabel, StylesProvider} from "@material-ui/core";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
// Actions
import { getProjectOfUser} from "../../actions/projectActions";
import { getSchachts } from "../../actions/schachtActions";
//IMG
import logo from "../../img/logo_mobile.png";
//Icons
import { DonutLarge, Waves, Photo, Nature, FullscreenExit, Opacity, FlashOn, HelpOutline, Healing, LocalFlorist, Build, CheckCircle, TripOrigin, Map, List } from '@material-ui/icons';
const icons = {
    1: <DonutLarge />,
    2: <Waves />,
    3: <FullscreenExit />,
	4: <FlashOn />,
	5: <FlashOn />,
	6: <FlashOn />,
	7: <FlashOn />,
	8: <Opacity />,
	9: <Opacity />,
	10: <Nature />,
	11: <LocalFlorist />,
	12: <Healing />,
	
};


class Dashboard extends Component {
	
  handleMapClick = param => e => {
  //  e.stopPropagation()
	this.props.getProjectOfUser(param.user_id, param.project_id, param.object_id)
	this.props.history.push("/map")
  }
  
  handleTableClick = param => e => {
  //  e.stopPropagation()
	this.props.getProjectOfUser(param.user_id, param.project_id, param.object_id)
	this.props.history.push("/table")
  }

  render() {
    const { projects, projectsLoading } = this.props.projects;

	let projectData = projects.map(project => (
		<GridListTile cols={1} key={project.rownr}>
		  <StylesProvider injectFirst>
            <div key={project.rownr} className="object-panel" onClick={this.handleMapClick(project)}>
			  <div className="info-container">
                <div className="object-icon">{icons[project.object_id]}</div>
			    <div className="object-name">{project.project_name}</div>
		        <div className="object-overview">
                  <div className="object-all">{project.cnt_all}</div>
			      <div className="object-complete">({project.cnt_complete}</div>
				  <div className="object-all">/</div>
				  <div className="object-incomplete">{project.cnt_incomplete})</div>
			    </div>
			  </div>
            </div>
		  </StylesProvider>
		</GridListTile>
    ));
	
    let content;


    if (projects.length > 0) {
      // At least one project
      content = (
        <>
		  <GridList cellHeight={220} cols={0} >
            {projectData}
		  </GridList>
        </>
      );
    } else {
      // No projects
      content = (
        <>
          <div className="projects">
            <div className="no-projects">
              <h1 className="header">Keine Objekte zugewiesen</h1>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="main-content">
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>Projekte</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
		  <Box component="main" className="wf-box-wrapper">
            <Container maxWidth="md" className="wf-container">
              <Paper elevation={5}>
		        {content}
              </Paper>
            </Container>
          </Box>
	  </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects
});

export default connect(
  mapStateToProps,
  {getProjectOfUser,
   getSchachts}
)(Dashboard);