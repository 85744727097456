import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AppBar, Toolbar, Typography, Grid, Container, Paper, Box, Button } from "@material-ui/core";
import { Cancel } from '@material-ui/icons';

import Spinner from "../layout/Spinner/Spinner";
import Sheet from "./Sheet";

import './Datasheet.scss';
import logo from "../../img/logo_mobile.png";

import { getSchachtDatasheet } from "../../actions/schachtActions";

class Datasheet extends Component {
	
  componentDidMount() {
    let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.add("top-nav-invisibile"); }
    this.props.getSchachtDatasheet(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.getSchachtDatasheet(this.props.match.params.id);
    }
  }
  
  handleMap = () => {this.props.history.push("/map");}
  handleReset = () => {this.props.history.goBack();}


  render() {
	const schacht = this.props.datasheet;
	  
    let datasheetContent
    if (Object.keys(schacht).length === 0 && schacht.constructor === Object) {
      datasheetContent = <Spinner />;
    } else if (Object.keys(schacht).length > 0) {
	  datasheetContent = <Sheet history={this.props.history} data={schacht.data} />
	}
	  
    return (
	    <div>
	        <AppBar position="static" elevation={0} color="default" className="wf-top-app-bar">
                <Toolbar className="wf-toolbar">
                    <Grid container item direction="row" alignItems="center" xs={12} sm={12}>
                         <Grid item>
                             <img src={logo} className="wf-logo-mobile" alt="logo" height="70px" />
                         </Grid>
                         <Grid item>
                            <Typography className="wf-title" variant="h5" noWrap>
                                 <b>Schachtprotokoll</b>
                            </Typography>
                         </Grid>
                     </Grid>
                </Toolbar>
            </AppBar>
	        <Box component="main" className="wf-box-wrapper">
                <Container maxWidth="md" className="wf-container">
                    <Paper elevation={5}>
						{datasheetContent}
				          <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button  size="small" variant="contained" onClick={this.handleMap}>
                                    Zur Karte
                                </Button>
                            </Grid>
						  </Grid>
                    </Paper>
                </Container>
            </Box>

	    </div>
    );
  }
}


Datasheet.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  datasheet: state.schachts.datasheet
});

export default connect(
  mapStateToProps,
    { getSchachtDatasheet }
)(Datasheet);