import axios from "axios";

import {
  UPLOAD_IMG
} from "./types";

//Upload Image
export const uploadImg = imageFormObj => dispatch => {
  axios
    .post("/api/upload", imageFormObj)
	.then(res =>
      dispatch({
        type: UPLOAD_IMG,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};