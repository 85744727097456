import React, { Component } from 'react';
import { TextField, Grid, Typography, MenuItem } from "@material-ui/core";

import './Step.scss';

class ArchitectureForm extends Component {
  
render() {
    return (
	   <>
        <Grid item xs={12}>
            <Typography variant="h6">Deckel</Typography>
        </Grid>
		<Grid item xs={12} sm={6}>
		  <TextField
            id={'id_material_deckel' + this.props.values.id}
            select
			required
            label="Material"
		    name="id_material_deckel"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_material_deckel}
            onChange={this.props.handleChange}
          >
            {this.props.materialDeckel.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
		</Grid>
		<Grid item xs={12} sm={6}>
		  <TextField
            id={'id_form_deckel' + this.props.values.id}
            select
			required
            label="Form"
		    name="id_form_deckel"
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.id_form_deckel}
            onChange={this.props.handleChange}
          >
            {this.props.form.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
		</Grid>
		<Grid item xs={12} sm={6}>
		  <TextField
            id={'dimension_1_deckel' + this.props.values.id}
            label="Dimension 1 [mm]"
		    name="dimension_1_deckel"
			type="number"
			InputProps={{ inputProps: { min: 0, max: 10000 } }}
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.dimension_1_deckel}
            onChange={this.props.handleChange}
          >
          </TextField>
		</Grid>
		<Grid item xs={12} sm={6}>
		  <TextField
            id={'dimension_2_deckel' + this.props.values.id}
            label="Dimension 2 [mm]"
		    name="dimension_2_deckel"
			type="number"
			InputProps={{ inputProps: { min: 0, max: 10000 } }}
		    variant="outlined"
		    fullWidth
		    className="step-input"
            value={this.props.values.dimension_2_deckel}
            onChange={this.props.handleChange}
          >
          </TextField>
		</Grid>
        <Grid item xs={12}>
            <Typography variant="h6">Geometrie Schacht</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id={'form' + this.props.values.id}
            select
            label="Form"
		    name="id_form"
		    variant="outlined"
			required
		    fullWidth
		    className="step-input"
            value={this.props.values.id_form}
            onChange={this.props.handleChange}
          >
            {this.props.form.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Dimension 1 [mm]"
            name="dimension_1"
			type="number"
			InputProps={{ inputProps: { min: 0, max: 10000 } }}
            variant="outlined"
            fullWidth
		  	value={this.props.values.dimension_1}
            onChange={this.props.handleChange}
		  	id={'dimension_1' + this.props.values.id}
		  	className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Dimension 2 [mm]"
            name="dimension_2"
			type="number"
			InputProps={{ inputProps: { min: 0, max: 10000 } }}
            variant="outlined"
            fullWidth
		    value={this.props.values.dimension_2}
            onChange={this.props.handleChange}
		  	id={'dimension_2' + this.props.values.id}
		  	className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Tiefe [m]"
            name="depth"
			type="number"
			required
			InputProps={{ inputProps: { min: 0.000, max: 20.000, step: 0.001} }}
            variant="outlined"
            fullWidth
		    value={this.props.values.depth}
            onChange={this.props.handleChange}
		  	id={'depth' + this.props.values.id}
		  	className="step-input"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Anzahl Einläufe"
            name="einlauf_cnt"
			type="number"
            variant="outlined"
            fullWidth
			disabled={this.props.values.einlauf_exist}
			value={this.props.values.einlauf_cnt}
            onChange={this.props.handleChange}
		  	id={'einlaeufe' + this.props.values.id}
		  	className="step-input"
			InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Anzahl Ausläufe"
            name="auslauf_cnt"
			type="number"
            variant="outlined"
            fullWidth
			disabled={this.props.values.auslauf_exist}
			value={this.props.values.auslauf_cnt}
            onChange={this.props.handleChange}
		  	id={'auslaeufe' + this.props.values.id}
		  	className="step-input"
			InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
    </>
	)
}
}

export default ArchitectureForm;
