import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider} from '@material-ui/core';

//Actions
import { uploadImg } from "../../actions/uploadActions";
import { getSchachts, updateSchachtAttr, updateSchachtZugang, updateSchachtKEK, updateSchachtCanvas, createDeckelZustand, createSchachtZustand, createPicture } from "../../actions/schachtActions";

import equal from 'fast-deep-equal'

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import KEKForm from "./Steps/KEKStep";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleCheckChange, handleRadioChange, handleArrayChangeKEK, addArrayElemet, deleteArrayElemet, onImgDrop, values, materialSchacht, funktion, zugang, form, status, nutzung, einstiegshilfe, schachtbereich, schadencode, kek}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} values={values} materialSchacht={materialSchacht} funktion={funktion} zugang={zugang} status={status} nutzung={nutzung} onImgDrop={onImgDrop}/>;
        case 1:
            return <KEKForm handleChange={handleChange} handleArrayChangeKEK={handleArrayChangeKEK} addArrayElemet={addArrayElemet} deleteArrayElemet={deleteArrayElemet} values={values} kek={kek} schachtbereich={schachtbereich} schadencode={schadencode} />;
        default:
            return <></>;
    }
}


class KEKSteppers extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Layout
  	  step: 1,
      activeStep: 0,
	  width: 0, 
	  height: 0,
	  scale: 1,
	  canvasWidth: 860,
	  dependZugang: true,
	  
	  // Schacht
	  id: '',
	  fid: '',
	  designation: '',
	  id_material: '',
	  id_function: '',
	  id_form: '',
	  id_zugang: '',
	  id_nutzung: '',
	  id_status: '',
	  id_oberflaechenzulauf: '',
	  dimension_1: '',
	  dimension_2: '',
	  depth: '',
	  standort: '',
	  hoehe_deckel: '',
	  hoehe_sohle: '',
	  bemerkung: '',
	  kek: [],
	  kek_cnt: 0,
	  pictures: [],
	  selectedImg: []
	}
	this.handleChange = this.handleChange.bind(this);
	this.handleCheckChange = this.handleCheckChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.handleArrayChangeKEK = this.handleArrayChangeKEK.bind(this);
	this.addArrayElemet = this.addArrayElemet.bind(this);
	this.deleteArrayElemet = this.deleteArrayElemet.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
}

  componentWillReceiveProps(nextProps) {
	  if ((nextProps.schacht.id !== this.props.schacht.id) || (nextProps.schacht.id !== null && this.state.id === '')) {this.setState({ id: nextProps.schacht.id }); }
	  if ((nextProps.schacht.fid !== this.props.schacht.fid && nextProps.schacht.fid !== null) || (nextProps.schacht.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.schacht.fid }); }
	  if ((nextProps.schacht.designation !== this.props.schacht.designation && nextProps.schacht.designation !== null) || (nextProps.schacht.designation && this.state.designation === '')) {this.setState({ designation: nextProps.schacht.designation }); }
	  if ((nextProps.schacht.id_oberflaechenzulauf !== this.props.schacht.id_oberflaechenzulauf && nextProps.schacht.id_oberflaechenzulauf !== null) || (nextProps.schacht.id_oberflaechenzulauf !== null && this.state.id_oberflaechenzulauf === '')) {this.setState({ id_oberflaechenzulauf: nextProps.schacht.id_oberflaechenzulauf }); }
	  if ((nextProps.schacht.id_material !== this.props.schacht.id_material && nextProps.schacht.id_material !== null) || (nextProps.schacht.id_material && this.state.id_material === '')) {this.setState({ id_material: nextProps.schacht.id_material }); }
	  if ((nextProps.schacht.id_function !== this.props.schacht.id_function && nextProps.schacht.id_function !== null) || (nextProps.schacht.id_function && this.state.id_function === '')) {this.setState({ id_function: nextProps.schacht.id_function }); }
	  if ((nextProps.schacht.id_nutzung !== this.props.schacht.id_nutzung && nextProps.schacht.id_nutzung !== null) || (nextProps.schacht.id_nutzung && this.state.id_nutzung === '')) {this.setState({ id_nutzung: nextProps.schacht.id_nutzung }); }
	  if ((nextProps.schacht.id_status !== this.props.schacht.id_status && nextProps.schacht.id_status !== null) || (nextProps.schacht.id_status && this.state.id_status === '')) {this.setState({ id_status: nextProps.schacht.id_status }); }
	  if ((nextProps.schacht.id_form !== this.props.schacht.id_form && nextProps.schacht.id_form !== null) || (nextProps.schacht.id_form && this.state.id_form === '')) {this.setState({ id_form: nextProps.schacht.id_form }); }
	  if ((nextProps.schacht.dimension_1 !== this.props.schacht.dimension_1 && nextProps.schacht.dimension_1 !== null) || (nextProps.schacht.dimension_1 && this.state.dimension_1 === '')) {this.setState({ dimension_1: nextProps.schacht.dimension_1 }); }
	  if ((nextProps.schacht.dimension_2 !== this.props.schacht.dimension_2 && nextProps.schacht.dimension_2 !== null) || (nextProps.schacht.dimension_2 && this.state.dimension_2 === '')) {this.setState({ dimension_1: nextProps.schacht.dimension_2 }); }
	  if ((nextProps.schacht.depth !== this.props.schacht.depth && nextProps.schacht.depth !== null) || (nextProps.schacht.depth && this.state.depth === '')) {this.setState({ depth: nextProps.schacht.depth }); }
	  if ((nextProps.schacht.standort !== this.props.schacht.standort && nextProps.schacht.standort !== null) || (nextProps.schacht.standort && this.state.standort === '')) {this.setState({ standort: nextProps.schacht.standort }); }
	  if ((nextProps.schacht.bemerkung !== this.props.schacht.bemerkung && nextProps.schacht.bemerkung !== null) || (nextProps.schacht.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.schacht.bemerkung }); }
      //Zugang
	  if ((nextProps.schacht.id_zugang !== this.props.schacht.id_zugang && nextProps.schacht.id_zugang !== null) || (nextProps.schacht.id_zugang && this.state.id_zugang === '')) {this.setState({ id_zugang: nextProps.schacht.id_zugang }); }
	  if (nextProps.schacht.id_zugang === 1) {this.setState({ dependZugang: false }); }
      // KEK
      if ((!equal(this.props.schacht.kek, nextProps.schacht.kek) && (nextProps.schacht.kek)) || (this.state.kek && nextProps.schacht.kek && this.state.kek.length === 0)) {this.setState({ kek: nextProps.schacht.kek }); }
      if (this.state.kek && nextProps.schacht.kek && this.state.kek.length > 0) {this.setState({ einlauf_cnt: nextProps.schacht.kek.length, einlauf_exist: true }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
	// Zugang frei zum Schacht
	if (name === 'id_zugang' && value === 1) {
	  this.setState({ dependZugang: false });
	} else if (name === 'id_zugang' && value !== 1) {
      this.setState({ dependZugang: true });
    }
  }
  
  handleArrayChangeKEK = index => event => {
    const { kek } = this.state;
    const newKEK = kek.slice(0); 
    newKEK[index].[event.target.name] = event.target.value;
    this.setState({ kek: newKEK });
  }

  handleCheckChange(event) {
    const {name, checked} = event.target
    this.setState({
      [name]: checked
    })
  }
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }
  
  addArrayElemet(name) {
    let objCnt = this.state.kek_cnt
	let objCntNew = +objCnt + 1;
	let objs = this.state.kek;
	let obj = { nummer: objCntNew, id_schachtbereich: '', id_schadencode: '', bemerkung: ''}
	objs.push( obj );
	this.setState({objs});
    this.setState({kek_cnt: objCntNew})
  };
  
  deleteArrayElemet(name, index) {
    let objCnt = this.state.kek_cnt
	let objCntNew = +objCnt - 1;
	let objs = this.state.kek;
    objs.splice(index, 1)
    this.setState({objs});
	this.setState({kek_cnt: objCntNew})
  };
  
  onImgDrop(picture) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(picture),
	  pictures: this.state.pictures.concat(picture),
    });
  }

  handleNext = () => {
	  
    if (this.state.activeStep === 0 && this.state.id_zugang !== 1) {
		
	  let EditData = {
        id: this.state.id,
		fid: this.state.fid,
        designation: this.state.designation,
        id_zugang: this.state.id_zugang,
		standort: this.state.standort,
		hoehe_deckel: this.state.hoehe_deckel,
		hoehe_sohle: this.state.hoehe_sohle,
		bemerkung: this.state.bemerkung
      };

	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.hoehe_deckel === '') {EditData.hoehe_deckel = null}
	  if (EditData.hoehe_sohle === '') {EditData.hoehe_sohle = null}
      this.props.updateSchachtZugang(EditData);
	  
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		imageFormObj.append('id', this.state.id)
		imageFormObj.append('nummer', nr)
        imageFormObj.append('file', this.state.selectedImg[i])
	    
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_schacht: this.state.id,
		  nummer: nr,
		  name: this.state.selectedImg[i].name,
		  pfad: '/uploads',
		  upload: newDate
        };
        this.props.createPicture(PhotoData)
      }

      this.setState({ loading: false, activeStep: 0  })
//	  this.props.getSchachts(this.props.projects.project.project_id, this.props.projects.project.object_id);
	  this.props.history.push("/map");
	}
	  
	if (this.state.activeStep === 0 && this.state.id_zugang === 1) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		imageFormObj.append('id', this.state.id)
		imageFormObj.append('nummer', nr)
        imageFormObj.append('file', this.state.selectedImg[i])
	    
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_schacht: this.state.id,
		  nummer: nr,
		  name: this.state.selectedImg[i].name,
		  pfad: '/uploads',
		  upload: newDate
        };
        this.props.createPicture(PhotoData)
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
	}

    if (this.state.activeStep === 1) {	

	  let EditData = {
        id: this.state.id,
		fid: this.state.fid,
        designation: this.state.designation,
        id_zugang: this.state.id_zugang,
        id_material: this.state.id_material,
        id_function: this.state.id_function,
        id_nutzung: this.state.id_nutzung,
        id_status: this.state.id_status,
		standort: this.state.standort,
		hoehe_deckel: this.state.hoehe_deckel,
		hoehe_sohle: this.state.hoehe_sohle,
		bemerkung: this.state.bemerkung,
		kek: this.state.kek
      };

	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.id_material === '') {EditData.id_material = null}
	  if (EditData.id_function === '') {EditData.id_function = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  if (EditData.id_nutzung === '') {EditData.id_nutzung = null}
	  if (EditData.id_status === '') {EditData.id_status = null}
	  if (EditData.hoehe_deckel === '') {EditData.hoehe_deckel = null}
	  if (EditData.hoehe_sohle === '') {EditData.hoehe_sohle = null}
	  if (!EditData.kek) {EditData.kek = []}
      this.props.updateSchachtKEK(EditData);

  
      this.props.updateSchachtKEK(EditData);
	  
	  this.setState({ activeStep: 0 })
	  this.props.history.push("/map");
       
    } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
	  this.props.history.push("/map");
  }
  

  render() {
	let buttonNext;
    if ((this.state.activeStep === 0 && this.state.id_zugang !== 1 ) || (this.state.activeStep === 1)) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleCheckChange={this.handleCheckChange}
						  handleRadioChange={this.handleRadioChange}
						  handleArrayChangeKEK={this.handleArrayChangeKEK}
						  addArrayElemet={this.addArrayElemet}
						  deleteArrayElemet={this.deleteArrayElemet}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  materialSchacht={this.props.materialSchacht}
						  funktion={this.props.funktion}
						  einstiegshilfe={this.props.einstiegshilfe}
						  schachtbereich={this.props.schachtbereich}
						  schadencode={this.props.schadencode}
						  form={this.props.form}
						  nutzung={this.props.nutzung}
						  status={this.props.status}
						  oberflaechenzulauf={this.props.oberflaechenzulauf}
						  zugang={this.props.zugang}
						  kek={this.props.kek}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  schacht: state.schachts.schacht,
  materialSchacht: state.schachts.materialSchacht,
  materialDeckel: state.schachts.materialDeckel,
  funktion: state.schachts.funktion,
  zugang: state.schachts.zugang,
  form: state.schachts.form,
  nutzung: state.schachts.nutzung,
  status: state.schachts.status,
  einstiegshilfe: state.schachts.einstiegshilfe,
  schachtbereich: state.schachts.schachtbereich,
  schadencode: state.schachts.schadencode,
  kek: state.schachts.kek,
  pictures: state.pictures
});

export default connect(
  mapStateToProps,
    { getSchachts,
	  uploadImg,
      updateSchachtAttr,
	  updateSchachtZugang,
	  updateSchachtKEK,
	  updateSchachtCanvas,
      createDeckelZustand,
      createSchachtZustand,
	  createPicture}
)(KEKSteppers);