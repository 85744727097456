import React, { Component,  } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Material-UI
import { Stepper, Step, StepLabel, Button, Box, Grid, CircularProgress, StylesProvider} from '@material-ui/core';

//Actions
import { uploadImg } from "../../actions/uploadActions";
import { getSchachts, updateSchachtAttr, updateSchachtZugang, updateSchachtCanvas, createDeckelZustand, createSchachtZustand, createPicture } from "../../actions/schachtActions";

import equal from 'fast-deep-equal'

//Stepper und Steps
import StepperIcons from "./StepperIcons";
import AttributForm from "./Steps/Step1";
import ArchitectureForm from "./Steps/Step2";
import Architecture from "./Steps/Step3";
import MaintenanceCoverForm from "./Steps/Step4";
import MaintenanceManholeForm from "./Steps/Step5";
import SubmitForm from "./Steps/Step6";

//import { useStateValue } from "../stateContext";
import StepConnector from './StepConnector'
import { Cancel } from '@material-ui/icons';

import './Stepper.scss';
 


const StepContent = ({ step , handleChange, handleCheckChange, handleRadioChange, handleCanvasChange, dragBoundFuncCircle, dragBoundFuncCircleDeckel, dragBoundFuncEllipse, dragBoundFuncEllipseDeckel, handleArrayChangeEinlauf, handleArrayChangeAuslauf, handleDragEnd, handleDragEndDeckel, handleDragChange, addArrayElemet, deleteArrayElemet, onImgDrop, values, materialSchacht, materialDeckel, funktion, zugang, form, status, nutzung, einstiegshilfe, einlauf, auslauf}) => {
    switch (step) {
        case 0:
            return <AttributForm handleChange={handleChange} values={values} materialSchacht={materialSchacht} funktion={funktion} zugang={zugang} status={status} nutzung={nutzung} onImgDrop={onImgDrop}/>;
        case 1:
            return <ArchitectureForm handleChange={handleChange} values={values} form={form} materialDeckel={materialDeckel}/>;
        case 2:
            return <Architecture handleChange={handleChange} handleCanvasChange={handleCanvasChange} handleRadioChange={handleRadioChange} dragBoundFuncCircle={dragBoundFuncCircle} dragBoundFuncCircleDeckel={dragBoundFuncCircleDeckel} dragBoundFuncEllipse={dragBoundFuncEllipse} dragBoundFuncEllipseDeckel={dragBoundFuncEllipseDeckel} handleArrayChangeEinlauf={handleArrayChangeEinlauf} handleArrayChangeAuslauf={handleArrayChangeAuslauf} handleDragEnd={handleDragEnd} handleDragEndDeckel={handleDragEndDeckel} handleDragChange={handleDragChange} addArrayElemet={addArrayElemet} deleteArrayElemet={deleteArrayElemet} values={values} einlauf={einlauf} auslauf={auslauf} materialSchacht={materialSchacht} />;
        case 3:
            return <MaintenanceCoverForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} />;
        case 4:
            return <MaintenanceManholeForm values={values} handleChange={handleChange} handleCheckChange={handleCheckChange} handleRadioChange={handleRadioChange} materialDeckel={materialDeckel} einstiegshilfe={einstiegshilfe}/>;
        case 5:
            return <SubmitForm values={values} materialSchacht={materialSchacht} zugang={zugang} funktion={funktion} status={status} nutzung={nutzung} form={form} />;
        default:
            return <></>;
    }
}


class CustomizedSteppers extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  //Layout
  	  step: 1,
      activeStep: 0,
	  width: 0, 
	  height: 0,
	  scale: 1,
	  canvasWidth: 860,
	  dependZugang: true,
	  withControl: true,
	  
	  // Schacht
	  id: '',
	  fid: '',
	  designation: '',
	  id_material: '',
	  id_function: '',
	  id_form: '',
	  id_zugang: '',
	  id_nutzung: '',
	  id_status: '',
	  id_oberflaechenzulauf: '',
	  dimension_1: '',
	  dimension_2: '',
	  depth: '',
	  standort: '',
	  hoehe_deckel: '',
	  hoehe_sohle: '',
	  bemerkung: '',
	  einlauf: [],
	  einlauf_cnt: 1,
	  einlauf_exist: false,
	  auslauf: [],
	  auslauf_cnt: 1,
	  auslauf_exist: false,
	  pictures: [],
	  selectedImg: [],
	  canvasObject: {},
	  canvasArray: {},
	  deckel_x: 0,
	  deckel_y: 0,
	  id_material_deckel: '',
	  id_form_deckel: '',
	  dimension_1_deckel: '',
	  dimension_2_deckel: '',

      // Zustand Deckel
	  ist_ersetze_deckel: null,
      ist_ersetzte_rahmen: null,
	  ist_maengel_deckel: null,
      ist_maengel_rahmen: null,
	  ist_belueftung: null,
	  ist_verschluss: null,
	  ist_deckel_defekt: false,
      ist_deckel_korrodiert: false,
      ist_deckel_klemmt: false,
      ist_deckel_verschraubt: false,
      ist_rahmen_lose: false,
      ist_rahmen_mangel_unterbetoniert: false,
	  ist_belueftung_schlammeimer: false,
      bemerkung_zustand_deckel: '',

      // Zustand Schacht
      ist_bankett: null,
      ist_sanierung: null,
      ist_massnahmen: null,
      ist_grundwassereintritt: null,
      ist_maengel: null,
      ist_einstiegshilfe: null,
      ist_undicht: null,
      ist_durchlaufrinne: null,
      ist_maengel_schachthals: false,
      ist_maengel_schachtrohrfugen: false,
      ist_maengel_seitl_anschl_einfuhr: false,
      ist_maengel_seitl_anschl_verputzt: false,
      ist_maengel_einlauf_verputzt: false,
      ist_maengel_auslauf_verputzt: false,
      ist_einstiegshilfe_notwendig: false,
      ist_einstiegshilfe_verrostet: false,
      ist_einstiegshilfe_zustand: false,
	  ist_einstiegshilfe_abstieg: false,
      ist_undicht_schachtrohr: false,
      ist_undicht_konus: false,
      ist_durchlaufrinne_ablagerung: false,
      ist_durchlaufrinne_ausgebildet: false,
	  ist_durchlaufrinne_scheitel: false,
	  ist_durchlaufrinne_halb_ausgebildet: false,
	  id_einstiegshilfe_typ: '',
      bemerkung_zustand_schacht: ''

	}
	this.handleChange = this.handleChange.bind(this);
	this.handleCheckChange = this.handleCheckChange.bind(this);
	this.handleRadioChange = this.handleRadioChange.bind(this);
	this.handleArrayChangeEinlauf = this.handleArrayChangeEinlauf.bind(this);
	this.handleArrayChangeAuslauf = this.handleArrayChangeAuslauf.bind(this);
	this.handleCanvasChange = this.handleCanvasChange.bind(this);
	this.handleDragChange = this.handleDragChange.bind(this);
	this.handleDragEnd = this.handleDragEnd.bind(this);
	this.handleDragEndDeckel = this.handleDragEndDeckel.bind(this);
	this.dragBoundFuncCircle = this.dragBoundFuncCircle.bind(this);
	this.dragBoundFuncCircleDeckel = this.dragBoundFuncCircleDeckel.bind(this);
	this.dragBoundFuncEllipse = this.dragBoundFuncEllipse.bind(this);
	this.dragBoundFuncEllipseDeckel = this.dragBoundFuncEllipseDeckel.bind(this);
	this.addArrayElemet = this.addArrayElemet.bind(this);
	this.deleteArrayElemet = this.deleteArrayElemet.bind(this);
	this.onImgDrop = this.onImgDrop.bind(this);
	this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 1, canvasWidth: 860 });
	  if (window.innerWidth > 600 && window.innerWidth <= 970) {
	      this.setState({ width: window.innerWidth, height: window.innerHeight, canvasWidth: 500 });
	  } else if (window.innerWidth <= 600) {
		  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 0.8, canvasWidth: 360 });
	  } else if (window.innerWidth <= 450) {
		  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 0.8, canvasWidth: 280 });
	  } else {
		  this.setState({ width: window.innerWidth, height: window.innerHeight, scale: 1, canvasWidth: 860 });
	  }
  }

  componentWillReceiveProps(nextProps) {
	  if ((nextProps.schacht.id !== this.props.schacht.id) || (nextProps.schacht.id !== null && this.state.id === '')) {this.setState({ id: nextProps.schacht.id }); }
	  if ((nextProps.schacht.fid !== this.props.schacht.fid && nextProps.schacht.fid !== null) || (nextProps.schacht.fid !== null && this.state.fid === '')) {this.setState({ fid: nextProps.schacht.fid }); }
	  if ((nextProps.schacht.designation !== this.props.schacht.designation && nextProps.schacht.designation !== null) || (nextProps.schacht.designation && this.state.designation === '')) {this.setState({ designation: nextProps.schacht.designation }); }
	  if ((nextProps.schacht.id_oberflaechenzulauf !== this.props.schacht.id_oberflaechenzulauf && nextProps.schacht.id_oberflaechenzulauf !== null) || (nextProps.schacht.id_oberflaechenzulauf !== null && this.state.id_oberflaechenzulauf === '')) {this.setState({ id_oberflaechenzulauf: nextProps.schacht.id_oberflaechenzulauf }); }
	  if ((nextProps.schacht.id_material !== this.props.schacht.id_material && nextProps.schacht.id_material !== null) || (nextProps.schacht.id_material && this.state.id_material === '')) {this.setState({ id_material: nextProps.schacht.id_material }); }
	  if ((nextProps.schacht.id_function !== this.props.schacht.id_function && nextProps.schacht.id_function !== null) || (nextProps.schacht.id_function && this.state.id_function === '')) {this.setState({ id_function: nextProps.schacht.id_function }); }
	  if ((nextProps.schacht.id_nutzung !== this.props.schacht.id_nutzung && nextProps.schacht.id_nutzung !== null) || (nextProps.schacht.id_nutzung && this.state.id_nutzung === '')) {this.setState({ id_nutzung: nextProps.schacht.id_nutzung }); }
	  if ((nextProps.schacht.id_status !== this.props.schacht.id_status && nextProps.schacht.id_status !== null) || (nextProps.schacht.id_status && this.state.id_status === '')) {this.setState({ id_status: nextProps.schacht.id_status }); }
	  if ((nextProps.schacht.id_form !== this.props.schacht.id_form && nextProps.schacht.id_form !== null) || (nextProps.schacht.id_form && this.state.id_form === '')) {this.setState({ id_form: nextProps.schacht.id_form }); }
	  if ((nextProps.schacht.dimension_1 !== this.props.schacht.dimension_1 && nextProps.schacht.dimension_1 !== null) || (nextProps.schacht.dimension_1 && this.state.dimension_1 === '')) {this.setState({ dimension_1: nextProps.schacht.dimension_1 }); }
	  if ((nextProps.schacht.dimension_2 !== this.props.schacht.dimension_2 && nextProps.schacht.dimension_2 !== null) || (nextProps.schacht.dimension_2 && this.state.dimension_2 === '')) {this.setState({ dimension_1: nextProps.schacht.dimension_2 }); }
	  if ((nextProps.schacht.depth !== this.props.schacht.depth && nextProps.schacht.depth !== null) || (nextProps.schacht.depth && this.state.depth === '')) {this.setState({ depth: nextProps.schacht.depth }); }
	  if ((nextProps.schacht.standort !== this.props.schacht.standort && nextProps.schacht.standort !== null) || (nextProps.schacht.standort && this.state.standort === '')) {this.setState({ standort: nextProps.schacht.standort }); }
	  if ((nextProps.schacht.bemerkung !== this.props.schacht.bemerkung && nextProps.schacht.bemerkung !== null) || (nextProps.schacht.bemerkung && this.state.bemerkung === '')) {this.setState({ bemerkung: nextProps.schacht.bemerkung }); }
	  if ((nextProps.schacht.id_material_deckel !== this.props.schacht.id_material_deckel && nextProps.schacht.id_material_deckel !== null) || (nextProps.schacht.id_material_deckel && this.state.id_material_deckel === '')) {this.setState({ id_material_deckel: nextProps.schacht.id_material_deckel }); }
	  if ((nextProps.schacht.id_form_deckel !== this.props.schacht.id_form_deckel && nextProps.schacht.id_form_deckel !== null) || (nextProps.schacht.id_form_deckel && this.state.id_form_deckel === '')) {this.setState({ id_form_deckel: nextProps.schacht.id_form_deckel }); }
	  if ((nextProps.schacht.dimension_1_deckel !== this.props.schacht.dimension_1_deckel && nextProps.schacht.dimension_1_deckel !== null) || (nextProps.schacht.dimension_1_deckel && this.state.dimension_1_deckel === '')) {this.setState({ dimension_1_deckel: nextProps.schacht.dimension_1_deckel }); }
	  if ((nextProps.schacht.dimension_2_deckel !== this.props.schacht.dimension_2_deckel && nextProps.schacht.dimension_2_deckel !== null) || (nextProps.schacht.dimension_2_deckel && this.state.dimension_2_deckel === '')) {this.setState({ dimension_2_deckel: nextProps.schacht.dimension_2_deckel }); }
	  if ((nextProps.schacht.hoehe_deckel !== this.props.schacht.hoehe_deckel && nextProps.schacht.hoehe_deckel !== null) || (nextProps.schacht.hoehe_deckel && this.state.hoehe_deckel === '')) {this.setState({ hoehe_deckel: nextProps.schacht.hoehe_deckel }); }
	  if ((nextProps.schacht.hoehe_sohle !== this.props.schacht.hoehe_sohle && nextProps.schacht.hoehe_sohle !== null) || (nextProps.schacht.hoehe_sohle && this.state.hoehe_sohle === '')) {this.setState({ hoehe_sohle: nextProps.schacht.hoehe_sohle }); }
      //Zugang
	  if ((nextProps.schacht.id_zugang !== this.props.schacht.id_zugang && nextProps.schacht.id_zugang !== null) || (nextProps.schacht.id_zugang && this.state.id_zugang === '')) {this.setState({ id_zugang: nextProps.schacht.id_zugang }); }
	  if (nextProps.schacht.id_zugang === 1) {this.setState({ dependZugang: false }); }
      // Einlauf
      if ((!equal(this.props.schacht.einlauf, nextProps.schacht.einlauf) && (nextProps.schacht.einlauf)) || (this.state.einlauf && nextProps.schacht.einlauf && this.state.einlauf.length === 0)) {this.setState({ einlauf: nextProps.schacht.einlauf }); }
      if (this.state.einlauf && nextProps.schacht.einlauf && this.state.einlauf.length > 0) {this.setState({ einlauf_cnt: nextProps.schacht.einlauf.length, einlauf_exist: true }); }
      // Auslauf
      if ((!equal(this.props.schacht.auslauf, nextProps.schacht.auslauf) && (nextProps.schacht.auslauf)) || (this.state.auslauf && nextProps.schacht.auslauf && this.state.auslauf.length === 0)) {this.setState({ auslauf: nextProps.schacht.auslauf }); }
      if (this.state.auslauf && nextProps.schacht.auslauf && this.state.auslauf.length > 0) {this.setState({ auslauf_cnt: nextProps.schacht.auslauf.length, auslauf_exist: true }); }
  }

  handleChange(event) {
	const {name, value} = event.target
    this.setState({
      [name]: value
    })
	// Zugang frei zum Schacht
	if (name === 'id_zugang' && value === 1) {
	  this.setState({ dependZugang: false });
	} else if (name === 'id_zugang' && value !== 1) {
      this.setState({ dependZugang: true });
    }
  }
  
  handleArrayChangeEinlauf = index => event => {
    const { einlauf } = this.state;
    const newEinlauf = einlauf.slice(0); 
    newEinlauf[index].[event.target.name] = event.target.value;
    this.setState({ einlauf: newEinlauf });
  }
  
  handleArrayChangeAuslauf = index => event => {
    const { auslauf } = this.state;
    const newAuslauf = auslauf.slice(0); 
    newAuslauf[index].[event.target.name] = event.target.value;
    this.setState({ auslauf: newAuslauf });
  }

  handleCheckChange(event) {
    const {name, checked} = event.target
    this.setState({
      [name]: checked
    })
  }
  
  handleRadioChange(event) {
    const {name, value} = event.target
	let newValue = value === 'true' ? true: false;
    this.setState({
      [name]: newValue
    })
  }
  
  handleCanvasChange(event) {
    this.setState({
      canvasArray: this.state.canvasObject.getSaveData()
    })
  }

  handleDragChange = (e) => {
    // Change Angle
	let radians = Math.atan2(e.target.y(), e.target.x()) + (Math.PI/2);
	if ( radians < 0 ) {
      radians += (2 * Math.PI);
    }
	let angle = Math.round( radians * (200/Math.PI));
	// Save to state
	let name = e.target.parent.attrs.name;
	let objs = this.state.[name];
	let obj = objs[e.target.id()-1];
	obj.richtung = angle;
//	obj.y = e.target.y();
	objs[e.target.id()-1] = obj;
	this.setState({objs});
  };
  
  handleDragEnd(e) {
    let name = e.target.parent.attrs.name;
	let objs = this.state.[name];
	let obj = objs[e.target.id()-1];
	obj.x = Math.round(e.target.x());
	obj.y = Math.round(e.target.y());
	objs[e.target.id()-1] = obj;
	this.setState({objs});
  };
  
   handleDragEndDeckel(e) {
	this.setState({
      deckel_x: Math.round(e.target.x()),
	  deckel_y: Math.round(e.target.y()),
    })
  };
  
  addArrayElemet(name) {
    let objName = name
	let nameCnt = [objName] + '_cnt'
    let objCnt = this.state.[nameCnt]
	let objCntNew = +objCnt + 1;
	let shortValue = objName.charAt(0).toUpperCase()
	let objs = this.state.[objName];
	let obj = { name: shortValue + objCntNew, nummer: objCntNew, kaliber: '', abstich: '', bemerkung: '', id_material: '', richtung: '', x: 0, y: 0}
	objs.push( obj );
	this.setState({objs});
    this.setState({[nameCnt]: objCntNew})
  };
  
  deleteArrayElemet(name, index) {
	let objName = name
	let nameCnt = [objName] + '_cnt'
    let objCnt = this.state.[nameCnt]
	let objCntNew = +objCnt - 1;
	let objs = this.state.[objName];
    objs.splice(index, 1)
    this.setState({objs});
	this.setState({[nameCnt]: objCntNew})
  };
  
  dragBoundFuncCircle(pos) {
    var x = 200 * this.state.scale;
    var y = 200 * this.state.scale;
    var radius = 160 * this.state.scale;
    var scale = radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
		
    if (scale < 0.99 || scale > 1.01)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }
  
  dragBoundFuncCircleDeckel(pos) {
    var x = 200 * this.state.scale;
    var y = 200 * this.state.scale;
    var radius = 100 * this.state.scale;
    var scale = radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
		
    if (scale < 1)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }

  dragBoundFuncEllipse(pos) {
    var x = 200 * this.state.scale;
    var y = 200 * this.state.scale;
	var a = 120 * this.state.scale;
	var b = 160 * this.state.scale;
	let radians = Math.atan2(pos.y - y, pos.x - x);
	var ellipse_x = a * Math.cos(radians);
	var ellipse_y = b * Math.sin(radians);
	var distance = Math.sqrt(Math.pow(ellipse_x, 2) + Math.pow(ellipse_y, 2))
    var scale = distance / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
	
    if (scale < 0.99 || scale > 1.01)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }
  
  dragBoundFuncEllipseDeckel(pos) {
    var x = 200 * this.state.scale;
    var y = 200 * this.state.scale;
	var a = 60 * this.state.scale;
	var b = 100 * this.state.scale;
	let radians = Math.atan2(pos.y - y, pos.x - x);
	var ellipse_x = a * Math.cos(radians);
	var ellipse_y = b * Math.sin(radians);
	var distance = Math.sqrt(Math.pow(ellipse_x, 2) + Math.pow(ellipse_y, 2))
    var scale = distance / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
	
    if (scale < 1)
      return {
        y: Math.round((pos.y - y) * scale + y),
        x: Math.round((pos.x - x) * scale + x),
      };
    else return pos;				
  }

  onImgDrop(picture) {
    this.setState({
      selectedImg: this.state.selectedImg.concat(picture),
	  pictures: this.state.pictures.concat(picture),
    });
  }

  handleNext = () => {
	  
    if (this.state.activeStep === 0 && this.state.id_zugang !== 1) {
		
	  let EditData = {
        id: this.state.id,
		fid: this.state.fid,
        designation: this.state.designation,
        id_zugang: this.state.id_zugang,
		standort: this.state.standort,
		hoehe_deckel: this.state.hoehe_deckel,
		hoehe_sohle: this.state.hoehe_sohle,
		bemerkung: this.state.bemerkung
      };

	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.hoehe_deckel === '') {EditData.hoehe_deckel = null}
	  if (EditData.hoehe_sohle === '') {EditData.hoehe_sohle = null}
      this.props.updateSchachtZugang(EditData);
	  
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		imageFormObj.append('id', this.state.id)
		imageFormObj.append('nummer', nr)
        imageFormObj.append('file', this.state.selectedImg[i])
	    
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_schacht: this.state.id,
		  nummer: nr,
		  name: this.state.selectedImg[i].name,
		  pfad: '/uploads',
		  upload: newDate
        };
        this.props.createPicture(PhotoData)
      }

      this.setState({ loading: false, activeStep: 0  })
//	  this.props.getSchachts(this.props.projects.project.project_id, this.props.projects.project.object_id);
	  this.props.history.push("/map");
	}
	  
	if (this.state.activeStep === 0 && this.state.id_zugang === 1) {
		
	  for (let i = 0; i < this.state.selectedImg.length; i++) {
        //uploads Photo
		let imageFormObj = new FormData()
		
		let d = new Date();
		let m = d.getMonth() + 1;
		let newDate = "" + d.getFullYear() + m + d.getDate() + Date.now();
		let nr = i + 1;
		imageFormObj.append('id', this.state.id)
		imageFormObj.append('nummer', nr)
        imageFormObj.append('file', this.state.selectedImg[i])
	    
	    this.props.uploadImg(imageFormObj)
		//Eintrag DB
        let PhotoData = {
          id_schacht: this.state.id,
		  nummer: nr,
		  name: this.state.selectedImg[i].name,
		  pfad: '/uploads',
		  upload: newDate
        };
        this.props.createPicture(PhotoData)
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
	}
	if (this.state.activeStep === 1) {
		
		if (!this.state.einlauf_exist) {
		  let ein
		  let einlauf = []
		  for (let i=1; i <= this.state.einlauf_cnt; i++) {
			ein = { name: 'E' + i, nummer: i, kaliber: '', abstich: '', bemerkung: '', id_material: '', richtung: '', x: 0, y: 0}
			einlauf.push( ein );
		  }
		  this.setState({
            einlauf: this.state.einlauf.concat(einlauf),
			einlauf_exist: true
          })
        }
		if (!this.state.auslauf_exist) {
		  let aus
		  let auslauf = []
		  for (let i=1; i <= this.state.auslauf_cnt; i++) {
			aus = { name: 'A' + i, nummer: i, kaliber: '', abstich: '', bemerkung: '', id_material: '', richtung: 0, x: 0, y: -160}
			auslauf.push( aus );
		  }
		  this.setState({
            auslauf: this.state.auslauf.concat(auslauf),
			auslauf_exist: true
          })
		}
        this.setState({ activeStep: this.state.activeStep + 1 });
	}
/*	if (this.state.activeStep === 2 && this.state.id_form < 3) {

      let EditData = {
        id: this.state.id,
        canvas_img: this.state.canvasArray
      };
	  this.props.updateSchachtCanvas(EditData);
	  
      this.setState({ activeStep: this.state.activeStep + 1 });
	}*/
	if (this.state.activeStep === 2 && !this.state.withControl) {
	  let EditData = {
        id: this.state.id,
		fid: this.state.fid,
        designation: this.state.designation,
        id_material: this.state.id_material,
        id_function: this.state.id_function,
        id_form: this.state.id_form,
        id_zugang: this.state.id_zugang,
        id_nutzung: this.state.id_nutzung,
        id_status: this.state.id_status,
        id_oberflaechenzulauf: this.state.id_oberflaechenzulauf,
        dimension_1: this.state.dimension_1,
        dimension_2: this.state.dimension_2,
        depth: this.state.depth,
		standort: this.state.standort,
		hoehe_deckel: this.state.hoehe_deckel,
		hoehe_sohle: this.state.hoehe_sohle,
		bemerkung: this.state.bemerkung,
		deckel_x: this.state.deckel_x,
		deckel_y: this.state.deckel_y,
	    id_material_deckel: this.state.id_material_deckel,
	    id_form_deckel: this.state.id_form_deckel,
	    dimension_1_deckel: this.state.dimension_1_deckel,
	    dimension_2_deckel: this.state.dimension_2_deckel,
		einlauf: this.state.einlauf,
		auslauf: this.state.auslauf,
		canvas_img: this.state.canvasArray
      };

	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.id_material === '') {EditData.id_material = null}
	  if (EditData.id_function === '') {EditData.id_function = null}
	  if (EditData.id_form === '') {EditData.id_form = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  if (EditData.id_nutzung === '') {EditData.id_nutzung = null}
	  if (EditData.id_status === '') {EditData.id_status = null}
	  if (EditData.id_oberflaechenzulauf === '') {EditData.id_oberflaechenzulauf = null}
	  if (EditData.dimension_1 === '') {EditData.dimension_1 = null}
	  if (EditData.dimension_2 === '') {EditData.dimension_2 = null}
	  if (EditData.depth === '') {EditData.depth = null}
	  if (EditData.standort === '') {EditData.standort = null}
	  if (EditData.hoehe_deckel === '') {EditData.hoehe_deckel = null}
	  if (EditData.hoehe_sohle === '') {EditData.hoehe_sohle = null}
	  if (EditData.bemerkung === '') {EditData.bemerkung = null}
	  if (EditData.deckel_x === '') {EditData.deckel_x = null}
	  if (EditData.deckel_y === '') {EditData.deckel_y = null}
	  if (EditData.id_material_deckel === '') {EditData.id_material_deckel = null}
	  if (EditData.id_form_deckel === '') {EditData.id_form_deckel = null}
	  if (EditData.dimension_1_deckel === '') {EditData.dimension_1_deckel = null}
	  if (EditData.dimension_2_deckel === '') {EditData.dimension_2_deckel = null}
	  if (!EditData.einlauf) {EditData.einlauf = []}
	  if (!EditData.auslauf) {EditData.auslauf = []}
	  if (!EditData.canvas_img) {EditData.canvas_img = []}
	  
      this.props.updateSchachtAttr(EditData);

	  this.setState({ activeStep: 0 })
	  this.props.history.push("/map");	  
	  
	}
	
    if (this.state.activeStep === 3) {	

	  let CreateData = {
		// Zustand Deckel
        id_schacht: this.state.id,
	    ist_ersetze_deckel: this.state.ist_ersetze_deckel,
        ist_ersetzte_rahmen: this.state.ist_ersetzte_rahmen,
	    ist_maengel_deckel: this.state.ist_maengel_deckel,
        ist_maengel_rahmen: this.state.ist_maengel_rahmen,
	    ist_deckel_defekt: this.state.ist_deckel_defekt,
        ist_deckel_korrodiert: this.state.ist_deckel_korrodiert,
        ist_deckel_klemmt: this.state.ist_deckel_klemmt,
        ist_deckel_verschraubt: this.state.ist_deckel_verschraubt,
        ist_rahmen_lose: this.state.ist_rahmen_lose,
        ist_rahmen_mangel_unterbetoniert: this.state.ist_rahmen_mangel_unterbetoniert,
		ist_belueftung: this.state.ist_belueftung,
		ist_belueftung_schlammeimer: this.state.ist_belueftung_schlammeimer,
		ist_verschluss: this.state.ist_verschluss,
        bemerkung_zustand_deckel: this.state.bemerkung_zustand_deckel
      };

      this.props.createDeckelZustand(CreateData);
      this.setState({ activeStep: this.state.activeStep + 1 });
	  
    }
    if (this.state.activeStep === 4) {	

	  let CreateData = {
		// Zustand Schacht
	    id_schacht: this.state.id,
		id_einstiegshilfe_typ: this.state.id_einstiegshilfe_typ,
        ist_bankett: this.state.ist_bankett,
        ist_sanierung: this.state.ist_sanierung,
        ist_massnahmen: this.state.ist_massnahmen,
        ist_grundwassereintritt: this.state.ist_grundwassereintritt,
        ist_maengel: this.state.ist_maengel,
        ist_einstiegshilfe: this.state.ist_einstiegshilfe,
        ist_undicht: this.state.ist_undicht,
        ist_durchlaufrinne: this.state.ist_durchlaufrinne,
        ist_maengel_schachthals: this.state.ist_maengel_schachthals,
        ist_maengel_schachtrohrfugen: this.state.ist_maengel_schachtrohrfugen,
        ist_maengel_seitl_anschl_einfuhr: this.state.ist_maengel_seitl_anschl_einfuhr,
        ist_maengel_seitl_anschl_verputzt: this.state.ist_maengel_seitl_anschl_verputzt,
        ist_maengel_einlauf_verputzt: this.state.ist_maengel_einlauf_verputzt,
        ist_maengel_auslauf_verputzt: this.state.ist_maengel_auslauf_verputzt,
        ist_einstiegshilfe_notwendig: this.state.ist_einstiegshilfe_notwendig,
        ist_einstiegshilfe_verrostet: this.state.ist_einstiegshilfe_verrostet,
        ist_einstiegshilfe_zustand: this.state.ist_einstiegshilfe_zustand,
		ist_einstiegshilfe_abstieg: this.state.ist_einstiegshilfe_abstieg,
        ist_undicht_schachtrohr: this.state.ist_undicht_schachtrohr,
        ist_undicht_konus: this.state.ist_undicht_konus,
        ist_durchlaufrinne_ablagerung: this.state.ist_durchlaufrinne_ablagerung,
        ist_durchlaufrinne_ausgebildet: this.state.ist_durchlaufrinne_ausgebildet,
		ist_durchlaufrinne_scheitel: this.state.ist_durchlaufrinne_scheitel,
		ist_durchlaufrinne_halb_ausgebildet: this.state.ist_durchlaufrinne_halb_ausgebildet,
        bemerkung_zustand_schacht: this.state.bemerkung_zustand_schacht
      };
	  
	  if (CreateData.id_einstiegshilfe_typ === '') {CreateData.id_einstiegshilfe_typ = null}
	  
      this.props.createSchachtZustand(CreateData);
      this.setState({ activeStep: this.state.activeStep + 1 });
	  
    }
    if (this.state.activeStep === 5) {	

	  let EditData = {
        id: this.state.id,
		fid: this.state.fid,
        designation: this.state.designation,
        id_material: this.state.id_material,
        id_function: this.state.id_function,
        id_form: this.state.id_form,
        id_zugang: this.state.id_zugang,
        id_nutzung: this.state.id_nutzung,
        id_status: this.state.id_status,
        id_oberflaechenzulauf: this.state.id_oberflaechenzulauf,
        dimension_1: this.state.dimension_1,
        dimension_2: this.state.dimension_2,
        depth: this.state.depth,
		standort: this.state.standort,
		hoehe_deckel: this.state.hoehe_deckel,
		hoehe_sohle: this.state.hoehe_sohle,
		bemerkung: this.state.bemerkung,
		deckel_x: this.state.deckel_x,
		deckel_y: this.state.deckel_y,
	    id_material_deckel: this.state.id_material_deckel,
	    id_form_deckel: this.state.id_form_deckel,
	    dimension_1_deckel: this.state.dimension_1_deckel,
	    dimension_2_deckel: this.state.dimension_2_deckel,
		einlauf: this.state.einlauf,
		auslauf: this.state.auslauf,
		canvas_img: this.state.canvasArray
      };

	  if (EditData.fid === '') {EditData.fid = null}
	  if (EditData.id_material === '') {EditData.id_material = null}
	  if (EditData.id_function === '') {EditData.id_function = null}
	  if (EditData.id_form === '') {EditData.id_form = null}
	  if (EditData.id_zugang === '') {EditData.id_zugang = null}
	  if (EditData.id_nutzung === '') {EditData.id_nutzung = null}
	  if (EditData.id_status === '') {EditData.id_status = null}
	  if (EditData.id_oberflaechenzulauf === '') {EditData.id_oberflaechenzulauf = null}
	  if (EditData.dimension_1 === '') {EditData.dimension_1 = null}
	  if (EditData.dimension_2 === '') {EditData.dimension_2 = null}
	  if (EditData.depth === '') {EditData.depth = null}
	  if (EditData.standort === '') {EditData.standort = null}
	  if (EditData.hoehe_deckel === '') {EditData.hoehe_deckel = null}
	  if (EditData.hoehe_sohle === '') {EditData.hoehe_sohle = null}
	  if (EditData.bemerkung === '') {EditData.bemerkung = null}
	  if (EditData.deckel_x === '') {EditData.deckel_x = null}
	  if (EditData.deckel_y === '') {EditData.deckel_y = null}
	  if (EditData.id_material_deckel === '') {EditData.id_material_deckel = null}
	  if (EditData.id_form_deckel === '') {EditData.id_form_deckel = null}
	  if (EditData.dimension_1_deckel === '') {EditData.dimension_1_deckel = null}
	  if (EditData.dimension_2_deckel === '') {EditData.dimension_2_deckel = null}
	  if (!EditData.einlauf) {EditData.einlauf = []}
	  if (!EditData.auslauf) {EditData.auslauf = []}
	  if (!EditData.canvas_img) {EditData.canvas_img = []}
	  
      this.props.updateSchachtAttr(EditData);
	  
	  this.setState({ activeStep: 0 })
//	  this.props.getSchachts(this.props.projects.project.project_id, this.props.projects.project.object_id);
	  this.props.history.push("/map");
       
    } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  
  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });;
  handleReset = () => {
	  this.setState({ activeStep: 0 });
//      this.props.getSchachts(this.props.projects.project.project_id, this.props.projects.project.object_id);
	  this.props.history.push("/map");
  }
  

  render() {
	let buttonNext;
	if ((this.state.activeStep === 0 && this.state.id_zugang !== 1 ) || (this.state.activeStep === 2 && !this.state.withControl) || (this.state.activeStep === 5)) {
	  buttonNext = (
	      <Button
            variant="contained"
            color="primary"
            className="stepper-button"
            type="submit"
            disabled={this.state.loading}
          >
          {
            this.state.loading
              ?
            <CircularProgress size={24} />
              :
            'Speichern'
                }
          </Button>
       )
	} else {
	  buttonNext = (
		<Button
          variant="contained"
          color="primary"
          className="stepper-button"
          type="submit"
          disabled={this.state.loading}
        >
        {
          this.state.loading
            ?
          <CircularProgress size={24} />
            :
          'Weiter'
              }
        </Button>
		)
	}	  
	  
	return (
        <>
            <Stepper alternativeLabel className="stepper" connector={<StepConnector />} activeStep={this.state.activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3, 4, 5, 6].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className="stepper-main-box" onSubmit={e => { e.preventDefault(); this.handleNext() }}>
                <form autoComplete="off" className="stepper-form" >
                    <Grid container spacing={1}>
                        <StepContent 
						  handleChange={this.handleChange}
						  handleCheckChange={this.handleCheckChange}
						  handleRadioChange={this.handleRadioChange}
						  handleArrayChangeEinlauf={this.handleArrayChangeEinlauf}
						  handleArrayChangeAuslauf={this.handleArrayChangeAuslauf}
						  handleCanvasChange={this.handleCanvasChange}
						  handleDragChange={this.handleDragChange}
						  handleDragEnd={this.handleDragEnd}
						  handleDragEndDeckel={this.handleDragEndDeckel}
						  dragBoundFuncCircle={this.dragBoundFuncCircle}
						  dragBoundFuncCircleDeckel={this.dragBoundFuncCircleDeckel}
						  dragBoundFuncEllipse={this.dragBoundFuncEllipse}
						  dragBoundFuncEllipseDeckel={this.dragBoundFuncEllipseDeckel}
						  addArrayElemet={this.addArrayElemet}
						  deleteArrayElemet={this.deleteArrayElemet}
						  onImgDrop={this.onImgDrop}
						  values={this.state}
						  materialSchacht={this.props.materialSchacht}
						  materialDeckel={this.props.materialDeckel}
						  funktion={this.props.funktion}
						  einstiegshilfe={this.props.einstiegshilfe}
						  form={this.props.form}
						  nutzung={this.props.nutzung}
						  status={this.props.status}
						  oberflaechenzulauf={this.props.oberflaechenzulauf}
						  zugang={this.props.zugang}
						  einlauf={this.props.einlauf}
						  auslauf={this.props.auslauf}
						  step={this.state.activeStep}
                        />
						<StylesProvider injectFirst>
						  <Grid container item className="stepper-button-container" xs={12} sm={12}>
					        <Grid container item xs={4} sm={6} justify="flex-start">
					          <Button className="stepper-button-reset" onClick={this.handleReset}>
                                 <Cancel style={{fill: "red"}}/>
                              </Button>
					        </Grid>
                            <Grid container item xs={8} sm={6} justify="flex-end">
                                <Button disabled={this.state.activeStep === 0} className="stepper-button" onClick={this.handleBack}>
                                    ZURÜCK
                                </Button>
						        {buttonNext}  
                            </Grid>
						  </Grid>
						</StylesProvider>
                    </Grid>
                </form>
            </Box>
        </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  schacht: state.schachts.schacht,
  materialSchacht: state.schachts.materialSchacht,
  materialDeckel: state.schachts.materialDeckel,
  funktion: state.schachts.funktion,
  zugang: state.schachts.zugang,
  form: state.schachts.form,
  nutzung: state.schachts.nutzung,
  status: state.schachts.status,
  einstiegshilfe: state.schachts.einstiegshilfe,
  einlauf: state.schachts.einlauf,
  auslauf: state.schachts.auslauf,
  zustandDeckel: state.schachts.zustandDeckel,
  zustandSchacht: state.schachts.zustandSchacht,
  pictures: state.pictures
});

export default connect(
  mapStateToProps,
    { getSchachts,
	  uploadImg,
      updateSchachtAttr,
	  updateSchachtZugang,
	  updateSchachtCanvas,
      createDeckelZustand,
      createSchachtZustand,
	  createPicture}
)(CustomizedSteppers);