import React, { Component } from "react";
import { TextField, Grid, Typography, MenuItem, Button } from "@material-ui/core";
import { Stage, Layer, Text, Group, Circle, Ellipse, Path} from 'react-konva';
import CanvasDraw from 'react-canvas-draw';
import Konva from 'konva';
import { AddCircleOutline, HighlightOff } from '@material-ui/icons';

import './Step.scss';

class KEKForm extends Component {
	
render() {
    const keks = this.props.values.kek;

    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">KEK</Typography>
              </Grid>
			  <Grid container item xs={12} sm={12} >
			    <Grid item xs={1} sm={1} >
                 <Typography variant="h6">Schaden</Typography>
				 </Grid>
				 <Grid item xs={1} sm={1} >
				 <Button size="small" onClick={() => this.props.addArrayElemet('kek')}>
				   <AddCircleOutline style={{fill: "green"}}/>
				 </Button>
				 </Grid>
              </Grid>
		      {keks.map((x, index) => (
                 <Grid container spacing={1} item direction="row" id={index} key={index}>
                  <Grid item xs={12} sm={1}>
                    <TextField
                      label="Nummer"
                      name="nummer"
                      variant="outlined"
                      fullWidth
		              value={this.props.values.kek[index].nummer}
                      onChange={this.props.handleArrayChangeKEK(index)}
		            	id={'nummer' + index}
				  	    key={'nummer' + index}
		            	className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id={'schachtbereich' + this.props.values.id}
                      select
					  required
                      label="Schachtbereich"
		              name="id_schachtbereich"
		              variant="outlined"
		              fullWidth
		              className="step-input"
                      value={this.props.values.kek[index].id_schachtbereich}
                      onChange={this.props.handleArrayChangeKEK(index)}
                    >
                      {this.props.schachtbereich.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id={'schadencode' + this.props.values.id}
                      select
					  required
                      label="Schadencode"
		              name="id_schadencode"
		              variant="outlined"
		              fullWidth
		              className="step-input"
                      value={this.props.values.kek[index].id_schadencode}
                      onChange={this.props.handleArrayChangeKEK(index)}
                    >
                      {this.props.schadencode.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Bemerkung"
                      name="bemerkung"
                      variant="outlined"
                      fullWidth
					  value={this.props.values.kek[index].bemerkung}
                      onChange={this.props.handleArrayChangeKEK(index)}
		              id={'bemerkung' + index}
				  	  key={'bemerkung' + index}
		              className="step-input"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
				    <Button variant="outlined" size="large" onClick={() => this.props.deleteArrayElemet('kek', index)}>
				      <HighlightOff style={{fill: "red"}}/>
				    </Button>
                  </Grid>
				</Grid>
			   ))}

			</>
        );
    }
}

export default KEKForm;