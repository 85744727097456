import {
  CREATE_SCHACHT,
  UPDATE_SCHACHT_ATTR,
  UPDATE_SCHACHT_GEOM,
  UPDATE_SCHACHT_CANVAS,
  UPDATE_SCHACHT_ZUGANG,
  UPDATE_SCHACHT_KEK,
  DELETE_SCHACHT,
  GET_SCHACHT,
  GET_SCHACHT_DATASHEET,
  SCHACHT_LOADING,
  GET_SCHACHTS,
  SCHACHTS_LOADING,
  GET_DECKEL_ZUSTAND,
  GET_SCHACHT_ZUSTAND,
  CREATE_DECKEL_ZUSTAND,
  CREATE_SCHACHT_ZUSTAND,
  GET_MATERIAL_SCHACHT,
  GET_MATERIAL_DECKEL,
  GET_FUNKTION,
  GET_FORM,
  GET_NUTZUNG,
  GET_STATUS,
  GET_OBERFLAECHENZULAUF,
  GET_ZUGANG,
  GET_EINSTIEGSHILFE,
  GET_SCHACHTBEREICH,
  GET_SCHADENCODE,
  CREATE_PICTURE
} from "../actions/types";

const initialState = {
  schachts: [],
  schacht: [],
  datasheet: [],
  materialSchacht: [],
  materialDeckel: [],
  zustandDeckel: [],
  zustandSchacht: [],
  funktion: [],
  form: [],
  nutzung: [],
  status: [],
  oberflaechenzulauf: [],
  zugang: [],
  einstiegshilfe: [],
  schachtbereich: [],
  schadencode: [],
  picture: [],
  schachtLoading: false,
  schachtsLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_SCHACHT:
      return {
        ...state,
		schacht: action.payload,
        schachts: [action.payload, ...state.schachts]
      };
    case UPDATE_SCHACHT_ATTR:
      let indexAttr = state.schachts.findIndex(
        schacht => schacht._id === action.payload._id
      );

      state.schachts.splice(indexAttr, 1);

      return {
        ...state,
        schachts: [action.payload, ...state.schachts]
      };
    case UPDATE_SCHACHT_ZUGANG:
      let indexZugang = state.schachts.findIndex(
        schacht => schacht._id === action.payload._id
      );

      state.schachts.splice(indexZugang, 1);

      return {
        ...state,
        schachts: [action.payload, ...state.schachts]
      };
    case UPDATE_SCHACHT_KEK:
      let indexKEK = state.schachts.findIndex(
        schacht => schacht._id === action.payload._id
      );

      state.schachts.splice(indexKEK, 1);

      return {
        ...state,
        schachts: [action.payload, ...state.schachts]
      };
    case UPDATE_SCHACHT_GEOM:
      let indexGeom = state.schachts.findIndex(
        schacht => schacht._id === action.payload._id
      );
      state.schachts.splice(indexGeom, 1);
      return {
        ...state,
        schachts: [action.payload, ...state.schachts]
      };
    case UPDATE_SCHACHT_CANVAS:
      let indexCanvas = state.schachts.findIndex(
        schacht => schacht._id === action.payload._id
      );
      state.schachts.splice(indexCanvas, 1);
      return {
        ...state,
        schachts: [action.payload, ...state.schachts]
      };
    case DELETE_SCHACHT:
      return {
        ...state,
        schachts: state.schachts.filter(
          schacht => schacht._id !== action.payload
        )
      };
    case GET_SCHACHT:
      return {
        ...state,
        schacht: action.payload,
        schachtLoading: false
      };
    case GET_SCHACHT_DATASHEET:
      return {
        ...state,
        datasheet: action.payload,
        schachtLoading: false
      };
    case GET_SCHACHTS:
      return {
        ...state,
        schachts: action.payload,
        schachtsLoading: false
      };
    case SCHACHT_LOADING:
      return {
        ...state,
        schachtLoading: true
      };
    case SCHACHTS_LOADING:
      return {
        ...state,
        schachtsLoading: true
      };
    case GET_MATERIAL_SCHACHT:
      return {
        ...state,
        materialSchacht: action.payload,
      };
    case GET_MATERIAL_DECKEL:
      return {
        ...state,
        materialDeckel: action.payload,
      };
    case GET_FUNKTION:
      return {
        ...state,
        funktion: action.payload,
      };
	case GET_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case GET_NUTZUNG:
      return {
        ...state,
        nutzung: action.payload,
      };
	case GET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
	case GET_OBERFLAECHENZULAUF:
      return {
        ...state,
        oberflaechenzulauf: action.payload,
      };
	case GET_ZUGANG:
      return {
        ...state,
        zugang: action.payload,
      };
	case GET_EINSTIEGSHILFE:
      return {
        ...state,
        einstiegshilfe: action.payload,
      };
	case GET_SCHACHTBEREICH:
      return {
        ...state,
        schachtbereich: action.payload,
      };
	case GET_SCHADENCODE:
      return {
        ...state,
        schadencode: action.payload,
      };
    case GET_DECKEL_ZUSTAND:
      return {
        ...state,
		zustandDeckel: action.payload
      };
    case GET_SCHACHT_ZUSTAND:
      return {
        ...state,
		zustandSchacht: action.payload
      };
    case CREATE_DECKEL_ZUSTAND:
      return {
        ...state,
		zustandDeckel: action.payload
      };
    case CREATE_SCHACHT_ZUSTAND:
      return {
        ...state,
		zustandSchacht: action.payload
      };
    case CREATE_PICTURE:
      return {
        ...state,
		picture: action.payload
      };
    default:
      return state;
  }
}