import React, { Component } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider} from "@material-ui/core";

import './Step.scss';

class SubmitForm extends Component {

render() {
	let values = this.props.values
	let fidValue = '-'
	let materialValue = '-'
	let zugangValue = '-'
	let funktionValue = '-'
	let nutzungValue = '-'
	let statusValue = '-'
	let formValue = '-'
	if (values.fid) { fidValue = values.fid }
	if (values.id_material) { materialValue = this.props.materialSchacht.filter(entity => entity.id === values.id_material)[0].value }
	if (values.id_zugang) { zugangValue = this.props.zugang.filter(entity => entity.id === values.id_zugang)[0].value }
	if (values.id_function) { funktionValue = this.props.funktion.filter(entity => entity.id === values.id_function)[0].value }
	if (values.id_nutzung) { nutzungValue = this.props.nutzung.filter(entity => entity.id === values.id_nutzung)[0].value }
	if (values.id_status) { statusValue = this.props.status.filter(entity => entity.id === values.id_status)[0].value }
	if (values.id_form) { formValue = this.props.form.filter(entity => entity.id === values.id_form)[0].value }
  
    return (
		    <>
		      <Grid item xs={12} sm={12}>
                 <Typography variant="h6">Übersicht</Typography>
              </Grid>
			  <Grid item xs={12} sm={6}>
			    <List>
                  <ListItem>
                    <ListItemText primary="Feature-ID" />
					<ListItemText primary={fidValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Bezeichnung" />
					<ListItemText primary={values.designation} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Zugang" />
					<ListItemText primary={zugangValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Material" />
					<ListItemText primary={materialValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Funktion" />
					<ListItemText primary={funktionValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Nutzung" />
					<ListItemText primary={nutzungValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Status" />
					<ListItemText primary={statusValue} />
                  </ListItem>
                </List>
                <Divider />
			    <List>
                  <ListItem>
                    <ListItemText primary="Form" />
					<ListItemText primary={formValue} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Dimension 1" />
					<ListItemText primary={values.dimension_1} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Dimension 2" />
					<ListItemText primary={values.dimension_2} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Tiefe" />
					<ListItemText primary={values.depth} />
                  </ListItem>
                </List>
              </Grid>
			</>
        );
    }
}

export default SubmitForm;