import axios from "axios";

import {
  GET_PROJECTS_OF_USER,
  PROJECTS_LOADING,
  GET_PROJECT_OF_USER,
  PROJECT_LOADING
} from "./types";

// GET alle Elemente eines Benutzers
export const getProjectsOfUser = user_id => dispatch => {
  dispatch(setProjectsLoading());
  axios
    .get(`/api/board/${user_id}`)
    .then(res =>
      dispatch({
        type: GET_PROJECTS_OF_USER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROJECTS_OF_USER,
        payload: null
      })
    );
};

// Project loading
export const setProjectsLoading = () => {
  return {
    type: PROJECTS_LOADING
  };
};

// GET alle Project eines Benutzers
export const getProjectOfUser = (user_id, project_id, id) => dispatch => {
  dispatch(setProjectLoading());
  axios
    .get(`/api/board/${user_id}/project/${project_id}/object/${id}`)
    .then(res => {
	  const { user_id, project_id, object_id, start_lat, start_long, start_zoom } = res.data;
	  // Sichern der Projekt-Informationen in den lokalen Speicher
	  localStorage.setItem("user_id", user_id);
	  localStorage.setItem("project_id", project_id);
	  localStorage.setItem("object_id", object_id);
	  localStorage.setItem("start_lat", start_lat);
	  localStorage.setItem("start_long", start_long);
	  localStorage.setItem("start_zoom", start_zoom);
      dispatch({
        type: GET_PROJECT_OF_USER,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_PROJECT_OF_USER,
        payload: null
      })
    );
};

// Project loading
export const setProjectLoading = () => {
  return {
    type: PROJECT_LOADING
  };
};