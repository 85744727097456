import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { Link, withRouter } from "react-router-dom";

// Icons
import { IconContext } from "react-icons";
import { FaUser } from 'react-icons/fa';

import logo from "../../../img/logo_bolliger.png";

import "./TopNav.scss";

class TopNav extends Component {
  state = {
    dropdown: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  // Close dropdown when click outside
  handleClick = e => {
    if (this.state.dropdown && !this.node.contains(e.target)) {
      this.setState({ dropdown: !this.state.dropdown });
    }
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
    window.location.href = "/";
  };

  handleProfileClick = e => {
    this.setState({ dropdown: !this.state.dropdown });
  };

  // Show Side Nav
  toggleMenu = e => {
    let sideNav = document.querySelector(".side");
    sideNav.classList.remove("invisibile");

    let hamburger = document.querySelector(".hamburger-top-menu");
    hamburger.classList.remove("hamburger-visible");

    let rightSide = document.querySelector(".right");
    rightSide.classList.remove("no-side");

    let rightSideRight = document.querySelector(".right-top");
    rightSideRight.classList.remove("right-top-visibile");
  };

  render() {
    const { name, email } = this.props.auth.user;

    return (
      <nav className="top-nav" ref={node => (this.node = node)}>
        <div className="left-top">
          <i
            onClick={this.toggleMenu}
            className="material-icons hamburger-top-menu hamburger-visible"
          >
            menu
          </i>
          <Link to="/dashboard">
            <h1 className="brand-header">
              SCHACHTPROTOKOLLE
            </h1>
          </Link>
        </div>
		<div className="center-top">
		  <Link to="/dashboard">
		    <div className="logo">
              <img className="indicator" src={logo} alt="Loading..." />
            </div>
		  </Link>
		</div>
        <ul className="right-top right-top-visibile">
          <li>
            <div className="email">
              <p>Benutzer {email}</p>
            </div>
          </li>
          <li>
			<div className="user">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<FaUser />
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				  <a className="dropdown-item" href="/login" onClick={this.onLogoutClick}>Abmelden</a>
				</div>
			</div>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(TopNav));