import axios from "axios";

import {
  CREATE_SCHACHT,
  UPDATE_SCHACHT_GEOM,
  UPDATE_SCHACHT_ATTR,
  UPDATE_SCHACHT_ZUGANG,
  UPDATE_SCHACHT_KEK,
  UPDATE_SCHACHT_CANVAS,
  DELETE_SCHACHT,
  GET_SCHACHT,
  GET_SCHACHT_DATASHEET,
  SCHACHT_LOADING,
  GET_SCHACHTS,
  SCHACHTS_LOADING,
  GET_DECKEL_ZUSTAND,
  GET_SCHACHT_ZUSTAND,
  CREATE_DECKEL_ZUSTAND,
  CREATE_SCHACHT_ZUSTAND,
  GET_MATERIAL_SCHACHT,
  GET_MATERIAL_DECKEL,
  GET_FUNKTION,
  GET_FORM,
  GET_NUTZUNG,
  GET_STATUS,
  GET_OBERFLAECHENZULAUF,
  GET_ZUGANG,
  GET_EINSTIEGSHILFE,
  GET_SCHACHTBEREICH,
  GET_SCHADENCODE,
  CREATE_PICTURE
} from "./types";

// Create Schacht
export const createSchacht = schachtData => dispatch => {
  axios
    .post("/api/schacht/create", schachtData)
    .then(res =>
      dispatch({
        type: CREATE_SCHACHT,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht
export const updateSchachtAttr = schachtData => dispatch => {
  axios
    .patch("/api/schacht/update/attributes", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_SCHACHT_ATTR,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht Zugang
export const updateSchachtZugang = schachtData => dispatch => {
  axios
    .patch("/api/schacht/update/zugang", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_SCHACHT_ZUGANG,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht KEK
export const updateSchachtKEK = schachtData => dispatch => {
  axios
    .patch("/api/schacht/update/kek", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_SCHACHT_KEK,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht
export const updateSchachtGeom = schachtData => dispatch => {
  axios
    .patch("/api/schacht/update/geom", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_SCHACHT_GEOM,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Update Schacht Canvas
export const updateSchachtCanvas = schachtData => dispatch => {
  axios
    .patch("/api/schacht/update/canvas", schachtData)
    .then(res =>
      dispatch({
        type: UPDATE_SCHACHT_CANVAS,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Delete Schacht
export const deleteSchacht = id => dispatch => {
  axios
    .delete(`/api/schacht/delete/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_SCHACHT,
        payload: id
      })
    )
    .catch(err => console.log(err));
};

// Get specific schacht by id
export const getSchacht = id => dispatch => {
  dispatch(setSchachtLoading());
  axios
    .get(`/api/schacht/${id}`)
    .then(res =>
      dispatch({
        type: GET_SCHACHT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SCHACHT,
        payload: null
      })
    );
};

// Get specific schacht by id
export const getSchachtDatasheet = id => dispatch => {
  dispatch(setSchachtLoading());
  axios
    .get(`/api/schacht/datasheet/${id}`)
    .then(res =>
      dispatch({
        type: GET_SCHACHT_DATASHEET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SCHACHT_DATASHEET,
        payload: null
      })
    );
};

// Get all schacht for specific user
export const getSchachts = (project_id, object_id) => dispatch => {
  dispatch(setSchachtsLoading());
  axios
    .get(`/api/schacht/project/${project_id}/object/${object_id}`)
    .then(res =>
      dispatch({
        type: GET_SCHACHTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SCHACHTS,
        payload: null
      })
    );
};

// Schacht loading
export const setSchachtLoading = () => {
  return {
    type: SCHACHT_LOADING
  };
};

// Schacht loading
export const setSchachtsLoading = () => {
  return {
    type: SCHACHTS_LOADING
  };
};

export const getMaterialSchacht = () => dispatch => {
  axios
    .get("/api/schacht/material/schacht")
    .then(res =>
      dispatch({
        type: GET_MATERIAL_SCHACHT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_MATERIAL_SCHACHT,
        payload: null
      })
    );
};

export const getMaterialDeckel = () => dispatch => {
  axios
    .get("/api/schacht/material/deckel")
    .then(res =>
      dispatch({
        type: GET_MATERIAL_DECKEL,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_MATERIAL_DECKEL,
        payload: null
      })
    );
};

export const getFunktion = () => dispatch => {
  axios
    .get("/api/schacht/funktion")
    .then(res =>
      dispatch({
        type: GET_FUNKTION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_FUNKTION,
        payload: null
      })
    );
};

export const getForm = () => dispatch => {
  axios
    .get("/api/schacht/form")
    .then(res =>
      dispatch({
        type: GET_FORM,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_FORM,
        payload: null
      })
    );
};

export const getNutzung = () => dispatch => {
  axios
    .get("/api/schacht/nutzung")
    .then(res =>
      dispatch({
        type: GET_NUTZUNG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_NUTZUNG,
        payload: null
      })
    );
};

export const getStatus = () => dispatch => {
  axios
    .get("/api/schacht/status")
    .then(res =>
      dispatch({
        type: GET_STATUS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_STATUS,
        payload: null
      })
    );
};

export const getOberflaechenzulauf = () => dispatch => {
  axios
    .get("/api/schacht/oberflaechenzulauf")
    .then(res =>
      dispatch({
        type: GET_OBERFLAECHENZULAUF,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_OBERFLAECHENZULAUF,
        payload: null
      })
    );
};

export const getZugang = () => dispatch => {
  axios
    .get("/api/schacht/zugang")
    .then(res =>
      dispatch({
        type: GET_ZUGANG,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ZUGANG,
        payload: null
      })
    );
};

export const getEinstiegshilfe = () => dispatch => {
  axios
    .get("/api/schacht/einstiegshilfe")
    .then(res =>
      dispatch({
        type: GET_EINSTIEGSHILFE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_EINSTIEGSHILFE,
        payload: null
      })
    );
};

export const getSchachtbereich = () => dispatch => {
  axios
    .get("/api/schacht/schachtbereich")
    .then(res =>
      dispatch({
        type: GET_SCHACHTBEREICH,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SCHACHTBEREICH,
        payload: null
      })
    );
};

export const getSchadencode = () => dispatch => {
  axios
    .get("/api/schacht/schadencode")
    .then(res =>
      dispatch({
        type: GET_SCHADENCODE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SCHADENCODE,
        payload: null
      })
    );
};

export const getDeckelZustand = id => dispatch => {
  axios
    .get(`/api/schacht/${id}/zustanddeckel`)
    .then(res =>
      dispatch({
        type: GET_DECKEL_ZUSTAND,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DECKEL_ZUSTAND,
        payload: null
      })
    );
};

export const getSchachtZustand = id => dispatch => {
  axios
    .get(`/api/schacht/${id}/zustandschacht`)
    .then(res =>
      dispatch({
        type: GET_SCHACHT_ZUSTAND,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SCHACHT_ZUSTAND,
        payload: null
      })
    );
};

// Create Zustand Deckel
export const createDeckelZustand = deckelZustand => dispatch => {
  axios
    .post("/api/schacht/create/zustanddeckel", deckelZustand)
    .then(res =>
      dispatch({
        type: CREATE_DECKEL_ZUSTAND,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Zustand Schacht
export const createSchachtZustand = schachtZustand => dispatch => {
  axios
    .post("/api/schacht/create/zustandschacht", schachtZustand)
    .then(res =>
      dispatch({
        type: CREATE_SCHACHT_ZUSTAND,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};

// Create Zustand Schacht
export const createPicture = pictureData => dispatch => {
  axios
    .post("/api/schacht/create/picture", pictureData)
    .then(res =>
      dispatch({
        type: CREATE_PICTURE,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};