import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { getProjectOfUser } from "../../../actions/projectActions";

import "./SideNav.scss";

class SideNav extends Component {
  onLogoutClick = e => {
    this.props.logoutUser(this.props.history);
    window.location.href = "/";
  };
  
  setNewObject = param => e => {
  //  e.stopPropagation()
	this.props.getProjectOfUser(param.user_id, param.project_id, param.object_id)
  }

  // Hide Side Nav
  toggleMenu = e => {
    let sideNav = document.querySelector(".side");
    sideNav.classList.add("invisibile");

    let hamburger = document.querySelector(".hamburger-top-menu");
    hamburger.classList.add("hamburger-visible");

    let rightSide = document.querySelector(".right");
    rightSide.classList.add("no-side");

    let rightSideRight = document.querySelector(".right-top");
    rightSideRight.classList.add("right-top-visibile");
  };

  render() {
    const { projects } = this.props.projects;
	
	const projGroup = projects.reduce((r, a) => {
      r[a.project_name] = [...r[a.project_name] || [], a];
      return r;
     }, {});

    const projectData = Object.keys(projGroup).map(key => {
		return (
		<div key={key}>
          {projGroup[key].map(proj => {
            return (
              <li className="object-listing" key={proj.rownr}>
                <Link onClick={this.setNewObject(proj)} to={this.props.history.location.pathname}>{proj.project_name}</Link>
             </li>		 
            )
          })}
	    </div>
        )
	});

    return (
      <nav className="side invisibile">
        <ul className="top no-side">
          <li>
            <i
              onClick={this.toggleMenu}
              className="material-icons hamburger-side-menu"
            >
              menu
            </i>
          </li>
          <NavLink exact activeClassName="active-page" to="/dashboard">
            <li>
              <i className="material-icons icon">home</i>Home
            </li>
          </NavLink>
          {/*
          <NavLink exact activeClassName="active-page" to="/tasks">
            <li>
              <i className="material-icons icon">check_circle</i>My Tasks
            </li>
          </NavLink>
          */}
          <div className="sign-out" onClick={this.onLogoutClick}>
            <li>
              <i className="material-icons icon">arrow_back</i>Abmelden
            </li>
          </div>
        </ul>
        <ul className="bottom">
          <li>
            <h4 className="side-projects-header">Projekte</h4>
          </li>
          <div className="project-listings">{projectData}</div>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects
});

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser,
      getProjectOfUser	}
  )(withRouter(SideNav))
);